<template>
  <div class="uitemp">
    <div class="play-root">
      <video src="../../assets/video/在线演示.mp4" muted autoplay loop id="dplayer"></video>
      <div class="tit">
        <h3>助力建设、施工企业数字化升级</h3>
        <p>提供专业、便捷的数字化资产管理承载平台</p>
      </div>
    </div>
    <div class="temps">
      <ul>
        <li @click="touitemplate(item)" v-for="item in tempList" :key="item.projId">
          <div class="imgbox"><img :src="item.picUrl" alt=""></div>
          <div class="info">
            <span>{{item.name}}</span>
            <el-tag effect="plain" v-if="item.tags" style="margin-left:10px;">{{item.tags[0]}}</el-tag>
            <el-tag effect="plain" v-if="item.tags && item.tags.length > 1">{{item.tags[1]}}</el-tag>
            <el-tag effect="plain" v-if="item.tags && item.tags.length > 2">{{item.tags[2]}}</el-tag>
          </div>
        </li>
      </ul>
    </div>
    <!-- 分页 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="list.length"
      hide-on-single-page
      @current-change="currentChange">
    </el-pagination>
  </div>
</template>

<script>
import jifang from '../../assets/image/uitemp/jifang.png'
import bangongdalou from '../../assets/image/uitemp/bangongdalou.png'
import guanlang from '../../assets/image/uitemp/guanlang.png'
import chaogaoceng from '../../assets/image/uitemp/chaogaoceng.png'
import yingxiang from '../../assets/image/uitemp/yingxiang.png'
import bailuyuan from '../../assets/image/uitemp/bailuyuan.png'
import youeyuan from '../../assets/image/uitemp/youeyuan.jpg'
import gangjiegou from '../../assets/image/uitemp/gangjiegou.png'
import hsfdc from '../../assets/image/uitemp/hsfdc.png'
import gujianzhu_lunkuo from '../../assets/image/uitemp/gujianzhu_lunkuo.png'

export default {
  data () {
    return {
      list: [
        { name: '机房', projId: 'res_jifang', picUrl: jifang, tags: ['Revit模型'] },
        { name: '办公大楼', projId: 'res_yuqiao', picUrl: bangongdalou, tags: ['Revit模型'] },
        { name: '幼儿园', projId: 'res_youeryuanpd', picUrl: youeyuan, tags: ['Revit模型'] },
        // { name: '超高层建筑', projId: 'res_chaogaojianzhu', picUrl: chaogaoceng, tags: ['Revit模型'] },
        // { name: '隧道', projId: 'res_bailuyuan', picUrl: bailuyuan, tags: ['Bentley模型', '20.8GB'] },
        { name: '管廊', projId: 'res_guanlang', picUrl: guanlang, tags: ['Revit模型'] },
        // { name: '海上风电平台', projId: 'f21c5d021d044bd0b3e1c7cd180c34f6', picUrl: hsfdc, tags: ['Bentley模型'] },
        { name: '地区级倾斜摄影', projId: 'res_taizhou', picUrl: yingxiang, tags: ['Revit模型', 'OSGB数据', '60GB'] },
        { name: '某钢结构项目', projId: '8e5660163db341728594078ca1afeebc', picUrl: gangjiegou, tags: ['Tekla模型', 'Revit模型'] },
        { name: '古建筑白膜', projId: 'res_gujianzhu', picUrl: gujianzhu_lunkuo }
      ],
      tempList: [],
      currentPage: 1,
      pageSize: 8
    }
  },
  methods: {
    touitemplate (item) {
      sessionStorage.setItem('model', JSON.stringify(item))
      this.$router.push('/uitemplate')
    },
    currentChange (value) {
      this.getList(value, 8, this.list)
    },
    getList (currentPage, pageSize, array) {
      var offset = (currentPage - 1) * pageSize
      return this.tempList = (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize)
    }
  },
  created () {
    this.getList(1, 8, this.list)
  }
}
</script>

<style lang="less" scoped>
.uitemp{
  .play-root {
    width: 100%;
    height: 60vh;
    margin: 0 auto;
    position: relative;
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
    .tit{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 70px;
      color: #fff;
      @media screen and (max-width:600px) {
          left: 20px;
      }
      h3{
        font-size: 50px;
        font-family: "siyuan";
        @media screen and (max-width:600px) {
          font-size: 24px;
        }
      }
      p{
        font-size: 20px;
        line-height: 50px;
        @media screen and (max-width:600px) {
         font-size: 16px;
        line-height: 32px;
        }
      }
    }
  }
  .temps{
    max-width: 1200px;
    margin: 0 auto;
    // background-color: pink;
    // height: 1000px;
    ul{
      overflow: hidden;
      padding: 20px;
      li{
        width: 24%;
        margin-right: 1%;
        border: 1px solid #F4F3F4;
        margin-top: 20px;
        height: 270px;
        float: left;
        @media screen and (max-width:600px) {
          width: 100%;
        }
        @media screen and (min-width:600px) and (max-width:900px) {
          width: 48%;
        }
        @media screen and (min-width:900px) and (max-width:1200px) {
          width: 32%;
        }
        .imgbox{
          width: 100%;
          height: calc(100% - 40px);
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info{
          height: 40px;
          padding: 5px;
          line-height: 30px;
          color: #6F6F6F;
          font-size: 12px;
          overflow: hidden;
          .el-tag{
            margin-left: 5px;
          }
          .el-tag--small{
            padding: 0 2px;
          }
        }
      }
      li:nth-child(4n+4){
        margin-right: 0;
      }
      li:hover{
        cursor: pointer;
        box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
      }
      .more{
        text-align: center;
        line-height: 270px;
        font-size: 20px;
        color: rgb(56, 56, 56);
      }
    }
  }
  .el-pagination{
    text-align: center;
    padding: 30px 0;
  }
}
</style>
